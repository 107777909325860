import React, { createContext, useContext, useState } from 'react';
import SearchSymbolDialog from '../components/search_symbol_dialog';
import { useNavigation } from '../navigator';

const SearchDialogContext = createContext();

export function SearchDialogProvider({ children }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  useNavigation(() => {
    setIsDialogOpen(false);
  });

  const openSearchDialog = () => setIsDialogOpen(true);
  const closeSearchDialog = () => setIsDialogOpen(false);

  return (
    <SearchDialogContext.Provider value={{ openSearchDialog }}>
      {children}
      <SearchSymbolDialog
        isOpen={isDialogOpen}
        onClose={closeSearchDialog}
        onChange={() => { }}
      />
    </SearchDialogContext.Provider>
  );
}

export function useSearchDialog() {
  return useContext(SearchDialogContext);
}