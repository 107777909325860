import Tracker from './components/tracker';
import { useEffect } from 'react';

const subscribers = [];

const navigate = (url) => {
  Tracker.track('navigate', { url: url });
  Turbo.visit(url, { action: "advance", frame: "main" });
  subscribers.forEach(callback => callback(url));
}

// React hook for navigation events
const useNavigation = (callback) => {
  useEffect(() => {
    const unsubscribe = subscribeToNavigation(callback);
    return () => unsubscribe();
  }, [callback]);
};

// Subscribe to navigation events
const subscribeToNavigation = (callback) => {
  subscribers.push(callback);
  return () => {
    const index = subscribers.indexOf(callback);
    if (index > -1) {
      subscribers.splice(index, 1);
    }
  };
};

export { navigate, useNavigation };