const getColorClasses = (change) => ({
  background: parseFloat(change) >= 0 ? 'bg-emerald-900/30 hover:bg-emerald-900/50' : 'bg-red-900/30 hover:bg-red-900/50',
  text: parseFloat(change) >= 0 ? 'text-green-400' : 'text-rose-400',
  border: parseFloat(change) >= 0 ? 'border-green-300' : 'border-rose-400',
  borderTransparent: parseFloat(change) >= 0 ? 'border-green-300/30' : 'border-rose-400/30',
  chart: parseFloat(change) >= 0 ? '#22c55e' : '#ef4444',
  backgroundOpaque: parseFloat(change) >= 0 ? 'bg-emerald-700' : 'bg-red-700',
});

export { getColorClasses };
