import { useQuery } from '@tanstack/react-query';
import { market_quote_api_path } from '../routes';

// Request buffer
let pending = new Map(); // Map of symbol -> resolve function
let timeoutId = null;
const BUFFER_TIME = 50; // ms to wait before sending batch request

const batchQuotes = async (symbols) => {
  const response = await fetch(market_quote_api_path({ symbols: Array.from(symbols) }));
  const data = await response.json();
  if (!data || !data.quotes) {
    throw new Error('Invalid response format from quote API');
  }
  return data;
};

// Buffer manager
const createBufferedRequest = (symbol) => {
  return new Promise((resolve) => {
    pending.set(symbol, resolve);

    if (timeoutId) clearTimeout(timeoutId);

    timeoutId = setTimeout(async () => {
      const batchPending = new Map(pending);
      pending.clear();

      const results = await batchQuotes(batchPending.keys());
      for (const [sym, callback] of batchPending) {
        callback(results.quotes[sym] || null);
      }
    }, BUFFER_TIME);
  });
};

const useQuote = (symbol) => {
  return useQuery({
    queryKey: ["market_quote", symbol],
    queryFn: () => createBufferedRequest(symbol),
  });
};

export { useQuote };