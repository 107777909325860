import { useQuery } from '@tanstack/react-query';
import { market_stocklist_api_path } from '../routes';

export const useStockList = ({ listType = 'trending' }) => {
  return useQuery({
    queryKey: ["market_stocklist", listType],
    queryFn: () => fetch(market_stocklist_api_path({ list_type: listType })).then(res => res.json())
  });
};

export const useTrendingStocks = () => {
  return useStockList({ listType: 'trending' });
};
