import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ChevronLeftIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { Button } from './catalyst/button';
import { useTrendingStocks } from '../hooks/use_stocklists';
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { useStockSearch } from '../hooks/use_stock_search';
import Tracker from './tracker';
import StockRow from './stock_row';
import RecentVisits from './recent_visits';
import { FireIcon } from '@heroicons/react/24/solid';
interface SearchSymbolDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onChange: (symbol: string) => void;
}

const SearchSymbolDialog: React.FC<SearchSymbolDialogProps> = ({ isOpen, onClose, onChange }) => {
  const { stockResults, searchWithQuery, tickerDataPending } = useStockSearch();
  const [searchQuery, setSearchQuery] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const searchTrackTimer = useRef<NodeJS.Timeout>();

  const debouncedTrackSearch = useCallback((query: string) => {
    if (searchTrackTimer.current) {
      clearTimeout(searchTrackTimer.current);
    }

    searchTrackTimer.current = setTimeout(() => {
      Tracker.track('watchlist.search', { query });
    }, 3000);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        inputRef.current?.focus();
        inputRef.current?.click();
      }, 100);
      new Promise(resolve => {
        resolve(null);
      }).then(() => {
        inputRef.current?.focus();
        inputRef.current?.click();
      });
    }
  }, [isOpen]);

  const DialogHeader = () => (
    <div className="flex items-center gap-4 p-4 border-b border-zinc-800">
      <button onClick={onClose} className="text-zinc-400 hover:text-zinc-300">
        <ChevronLeftIcon className="w-6 h-6" />
      </button>
      <DialogTitle className="text-lg font-semibold text-zinc-100">
        Browse
      </DialogTitle>
    </div>
  );

  const SearchBar = () => (
    <div className="flex flex-col border-b border-zinc-800 bg-black pb-2">
      <div className="p-4 pb-2 relative flex gap-2 items-center">
        <div className="relative flex-1">
          <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
            {tickerDataPending ? (
              <div className="spinner h-5 w-5" />
            ) : (
              <MagnifyingGlassIcon className="h-5 w-5 text-zinc-400" />
            )}
          </div>
          <input
            type="search"
            ref={inputRef}
            autoFocus
            className="block w-full pl-10 pr-3 py-2 bg-zinc-800 border border-zinc-700 rounded-lg text-zinc-100 placeholder-zinc-400 focus:outline-none focus:border-dgreen-500 focus:ring-1 focus:ring-dgreen-500"
            placeholder="Search by name or ticker"
            autoComplete="off"
            aria-autocomplete="both"
            autoCorrect="off"
            autoCapitalize="off"
            enterKeyHint="search"
            onClick={() => {
              new Promise(resolve => resolve(null)).then(() => {
                inputRef.current?.focus();
              });
            }}
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              searchWithQuery(e.target.value);
              debouncedTrackSearch(e.target.value);
            }}
          />
        </div>
        <Button plain onClick={onClose} className="text-zinc-400 bg-zinc-900 border border-zinc-800">
          <span className="text-dgreen-400 text-sm font-normal">Close</span>
        </Button>
      </div>
      {!searchQuery && <RecentVisits className="mt-0" />}
    </div>
  );

  const resultListHeight = 'calc(100vh - 130px)';
  const maxResultsCount = 8;

  const TrendingStocks = () => {
    const { data: trendingResponse, isPending: trendingPending } = useTrendingStocks();
    return !trendingPending && trendingResponse?.trending.length > 0 && (
      <div className="flex flex-col">
        <div className="mt-2 text-zinc-400 text-sm font-semibold flex flex-row items-center gap-1"><FireIcon className="w-4 h-4" />Trending</div>
        <div className="divide-y divide-zinc-800 flex flex-col">
          {trendingResponse?.trending.slice(0, maxResultsCount).map((stock) => (
            <div key={stock.symbol}>
              <StockRow
                stock={stock}
                onClose={onClose}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const ResultsList = () => (
    <div className="overflow-y-auto px-4" style={{ height: resultListHeight }}>
      {stockResults?.length > 0 ? (
        <div className="flex flex-col">
          <div className="mt-2 text-zinc-400 text-sm font-semibold">Results</div>
          <div className="divide-y divide-zinc-800">
            {stockResults.slice(0, maxResultsCount).map((stock) => (
              <StockRow
                key={stock.symbol}
                stock={stock}
                onClose={onClose}
              />
            ))}
          </div>
        </div>
      ) : (!searchQuery ? <TrendingStocks /> : (
        <div className="flex p-4 justify-center items-center">No Results</div>
      ))}
    </div>
  );

  return (
    <>
      {isOpen && (
        <Dialog open={true} onClose={onClose} className="relative z-50">
          <div className="fixed inset-0 bg-black/50 backdrop-blur-sm" aria-hidden="true" />
          <div className="fixed inset-0 flex items-center justify-center">
            <div className="w-full md:max-w-lg h-full md:h-[45vh] md:min-h-[500px]">
              <DialogPanel className="w-full h-full bg-zinc-900 border border-zinc-800 md:rounded-xl overflow-hidden">
                <DialogHeader />
                <SearchBar />
                <ResultsList />
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default SearchSymbolDialog;