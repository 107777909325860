import React, { useState, memo } from 'react';
import { TrashIcon } from '@heroicons/react/24/solid';
import { symbol_image_path, show_symbol_path, watchlist_remove_api_path, watchlist_unpin_api_path, watchlist_pin_api_path } from "../routes";
import { navigate } from '../navigator';
import SparklineChart from './sparkline_chart';
import { getColorClasses } from '../utils/colors';
import Blaze from '../blaze';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useQueryClient } from '@tanstack/react-query';
import { invalidateWatchlist } from '../hooks/use_watchlist';
import { Stock } from '../types/stock';
import { VolatilityInfo } from './volatility_info';
import { PinIcon as PinSolid } from '@sidekickicons/react/24/solid';
import { PinIcon as PinOutline } from '@sidekickicons/react/24/outline';
import * as Sentry from '@sentry/react';

const StockInfo = ({ stock }: { stock: Stock }) => {
  const imageUrl = symbol_image_path(stock.symbol)
  return (
    <div className="flex flex-row items-center w-[135px]">
      <div className="w-8 h-8 mx-2 flex items-center relative">
        <img src={imageUrl} alt={stock.name} className="min-w-8 min-h-8 rounded-full block" />
      </div>
      <div className="ml-1 mt-[2px] w-auto h-12 flex flex-col items-start text-left">
        <div className="flex flex-row items-center gap-x-1">
          <span className={` ${getColorClasses(stock.change).text}`}>{parseFloat(stock.change) >= 0 ? '▲' : '▼'}</span>
          <span className="mt-[1px] font-bold text-base text-dblue-200">{stock.symbol}</span>
        </div>
        <div className="mt-[-2px] text-zinc-500 text-sm truncate min-w-0 max-w-[100px]">{stock.name}</div>
      </div>
    </div>
  )
};

const PriceInfo = ({ stock }: { stock: Stock }) => {
  return (
    <div className="mt-[2px] w-[60px] h-full text-right mr-2">
      <div className="text-zinc-200 text-[12pt]">{stock.latestPrice ? stock.latestPrice.toFixed(2) : '-'}</div>
    </div>
  )
};

const PriceChange = ({ stock }: { stock: Stock }) => {
  if (stock.change === undefined) {
    return <div className="w-full text-sm text-right text-zinc-500"><span className='py-0.5 px-2'>-</span></div>;
  }

  const colors = getColorClasses(stock.change);
  return (
    <div className={`w-full text-sm text-right ${colors.text}`}>
      <span className='py-0.5 px-2'>{stock.change >= 0 ? '+' : ''}{stock.change.toFixed(2)}</span>
    </div>
  )
};

const PriceChangePct = ({ stock }: { stock: Stock }) => {
  if (stock.changePercent === undefined) {
    return <div className="px-1 w-[70px] mt-[1px] text-sm flex flex-col text-right text-zinc-500 rounded-lg border border-zinc-800">
      <span className="py-0.5 text-[12pt] font-bold">-</span>
    </div>;
  }

  const colors = getColorClasses(stock.change);
  return (
    <div className={`px-1 w-[70px] mt-[1px] text-sm flex flex-col text-right ${colors.text} ${colors.background} rounded-lg border ${colors.borderTransparent}`}>
      <span className="py-0.5 text-[12pt] font-bold">
        {stock.change >= 0 ? '+' : ''}{stock.changePercent.toFixed(2)}%
      </span>
    </div>
  );
};

const DeleteButton = ({ rowHeight, onClick }: { rowHeight: number, onClick: () => void }) => (
  <button
    className="bg-red-500 text-white flex items-center justify-center"
    style={{ height: `${rowHeight}px`, width: `${rowHeight}px` }}
    onClick={onClick}>
    <TrashIcon className="w-6 h-6" />
  </button>
);

const PinButton = ({ rowHeight, onClick, isPinned }: { rowHeight: number, onClick: () => void, isPinned: boolean }) => {
  const Icon = isPinned ? PinSolid : PinOutline;
  return (
    <button
      className={`bg-dblue-500 text-zinc-300 hover:text-yellow-500 flex items-center justify-center transition-colors`}
      style={{ height: `${rowHeight}px`, width: `${rowHeight}px` }}
      onClick={onClick}>
      <Icon className="w-6 h-6" />
    </button>
  );
};

const WatchlistItem = memo(({ stock, refetch, rowHeight = 66, showIV = false }: { stock: Stock, refetch: () => void, rowHeight?: number, showIV?: boolean }) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const [isPinned, setIsPinned] = useState(stock.pinned);
  const colors = getColorClasses(stock.change);
  const queryClient = useQueryClient();

  if (!stock) {
    return null;
  }

  const handleDelete = () => {
    setIsRemoving(true);
    fetch(watchlist_remove_api_path({ symbol: stock.symbol }), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Blaze.csrfToken(),
      },
    }).then(response => response.json())
      .then(() => {
        invalidateWatchlist(queryClient);
        refetch();
      });
  };

  const handlePin = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsPinned(!isPinned);
    fetch(isPinned ? watchlist_unpin_api_path({ symbol: stock.symbol }) : watchlist_pin_api_path({ symbol: stock.symbol }), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Blaze.csrfToken(),
      },
    }).then(response => response.json())
      .then(() => {
        invalidateWatchlist(queryClient);
        refetch();
      })
      .catch(() => {
        setIsPinned(isPinned);
      });
  };

  const WatchlistRow = ({ showIV = false }) => {
    return <div className={`h-full border-b border-zinc-800 cursor-pointer w-full flex flex-row items-center`}
      onClick={() => navigate(show_symbol_path(stock.symbol))}>
      <div className="h-fit grow flex flex-row gap-x-1 items-start pr-2">
        <StockInfo stock={stock} />
        <div className="grow h-10 items-center justify-center flex mr-1">
          <SparklineChart className="ml-3 !w-[60px] h-6" color={colors.chart} strokeWidth={1.5} symbol={stock.symbol} />
        </div>
        {showIV && <VolatilityInfo stock={stock} />}
        <div className="flex flex-col">
          <PriceInfo stock={stock} />
          <PriceChange stock={stock} />
        </div>
        <PriceChangePct stock={stock} />
      </div>
    </div>;
  }

  const height = isRemoving ? '0px' : `${rowHeight}px`;
  const tailwindHeight = "!h-[70px] h-[70px]";

  return (
    <div className={`transition-all duration-300 ease-in-out h-[${height}] ${isRemoving ? 'opacity-0' : `opacity-100`}`}>
      <Swiper
        slidesPerView="auto"
        className={`!w-full !h-[${height}]`}
        allowTouchMove={true}
        wrapperClass={`!h-[${height}]`}
        resistance={true}
        height={rowHeight}
        resistanceRatio={0.85}
      >
        <SwiperSlide className={`!w-full !h-[${height}]`}>
          <WatchlistRow showIV={showIV} />
        </SwiperSlide>
        <SwiperSlide className={`!w-fit pl-1 !h-[${height}]`}>
          <div className="flex w-fit h-full items-center justify-center">
            <PinButton rowHeight={rowHeight} onClick={handlePin} isPinned={isPinned} />
            <DeleteButton rowHeight={rowHeight} onClick={handleDelete} />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
});

export default memo(WatchlistItem);