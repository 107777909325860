// Entry point for the build script in your package.json
import "@hotwired/stimulus"
import "./controllers"
import "@hotwired/turbo-rails"
import "./blaze"
import "./htmx"
import "htmx.org"

import * as echarts from 'echarts/core'
import { CandlestickChart } from 'echarts/charts'
import {
  AxisPointerComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  MarkLineComponent,
} from 'echarts/components';
import { SVGRenderer } from 'echarts/renderers'
echarts.use([
  AxisPointerComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  MarkLineComponent,
  CandlestickChart,
  SVGRenderer
]);

import * as Sentry from "@sentry/react";
Sentry.init({
  dsn: "https://ad3b3166a26260167f830ad53870acf9@o4505971274612736.ingest.us.sentry.io/4508803568959488",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/app\.blazetrades\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default {};