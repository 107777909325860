import React from 'react';
import MarketSectors from './market_sectors';
import { StockListWidget } from './stocklist_widget';
import MarketIndexWidget from './market_index_widget';
import SearchInput from './search_input';
import { QueryClientProvider } from '@tanstack/react-query';
import UpcomingEarnings from './upcoming_earnings';
import { SearchDialogProvider } from '../contexts/search_dialog_context';
import RecentVisits from './recent_visits';
const ExploreComponent = () => {
  return (
    <QueryClientProvider client={window.Blaze.getQueryClient()}>
      <SearchDialogProvider>
        <div className="py-2 flex flex-col md:max-w-xl md:border-r border-zinc-800">
          <div className='px-4 hidden'>
            <h1 className='text-xl text-zinc-300 font-semibold'>Explore Markets</h1>
          </div>
          <div className='mx-4 mt-2 mb-2'>
            <SearchInput />
          </div>
          <RecentVisits />
          <MarketIndexWidget />
          <StockListWidget listType="trending" />
          <UpcomingEarnings />
          <StockListWidget listType="cheapest" />
          <MarketSectors />
        </div>
      </SearchDialogProvider>
    </QueryClientProvider>
  );
};

export default ExploreComponent;
