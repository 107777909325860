import { useQuery, useQueryClient } from '@tanstack/react-query';
import { user_recent_visits_api_path, user_recent_visits_clear_api_path, symbol_image_path, show_symbol_path } from '../routes';
import React from 'react';
import { useQuote } from '../hooks/use_quote';
import { getColorClasses } from '../utils/colors';
import { navigate, useNavigation } from '../navigator';

const StockPill = ({ symbol }) => {
  const { data: quote, isPending: isQuotePending } = useQuote(symbol);
  const colors = getColorClasses(quote?.changePercent);
  const background = isQuotePending ? "bg-zinc-900" : colors.background;
  const border = isQuotePending ? "border-zinc-800" : colors.borderTransparent;
  return (
    <div className={`cursor-pointer w-[140px] text-sm flex flex-row items-center gap-2 px-2 py-1 rounded-lg border ${background} ${border}`}
      onClick={() => {
        navigate(show_symbol_path({ symbol }));
      }}>
      <img src={symbol_image_path(symbol)} className="w-5 h-5 rounded-full" />
      <div className="text-sky-300 font-bold">{symbol}</div>
      {isQuotePending ? <div className="w-12 h-4 rounded-lg animate-pulse bg-zinc-800" /> : quote &&
        <div className={`w-[60px] ${colors.text} font-bold`}>{quote?.changePercent > 0 ? "+" : ""}{quote?.changePercent}%</div>}
    </div>
  );
};

const ClearButton = () => {
  const queryClient = useQueryClient();
  const [isClearing, setIsClearing] = React.useState(false);

  const handleClear = async () => {
    setIsClearing(true);
    try {
      await fetch(user_recent_visits_clear_api_path(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': window.Blaze.csrfToken(),
        },
      });
      queryClient.invalidateQueries({ queryKey: ['user-recent-visits'] });
    } finally {
      setIsClearing(false);
    }
  };

  return (
    <div
      onClick={handleClear}
      disabled={isClearing}
      className="text-dgreen-300 font-normal text-sm hover:opacity-80 disabled:opacity-50"
    >
      {isClearing ? "Clearing..." : "Clear all"}
    </div>
  );
};

const RecentVisits = ({ className = "" }) => {
  const queryClient = useQueryClient();
  const { data, isLoading, error } = useQuery({
    queryKey: ['user-recent-visits'],
    queryFn: () => fetch(user_recent_visits_api_path()).then(res => res.json()),
    refetchInterval: 8000,
  });

  useNavigation(() => {
    setTimeout(() => {
      queryClient.invalidateQueries({ queryKey: ['user-recent-visits'] });
    }, 3000);
  });

  if (isLoading || error) return null;
  if (data.visits.length === 0) return null;

  const maxRecents = 5;
  const sortedVisits = data.visits.sort((a, b) => new Date(b.visited_at) - new Date(a.visited_at)).slice(0, maxRecents);
  return (
    <div className={`mb-2 w-full flex flex-col items-start gap-1 ${className}`}>
      <div className="px-4 flex flex-row justify-between items-center w-full">
        <div className="text-zinc-400 text-sm font-semibold">Recent</div>
        <ClearButton />
      </div>
      <div className="px-4 flex flex-row gap-2 overflow-x-auto no-scrollbar w-full">
        {sortedVisits.map(visit => (
          <div key={visit.symbol}>
            <StockPill symbol={visit.symbol} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecentVisits;