import React, { useState, useCallback, useEffect } from 'react';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/solid';
import { format, differenceInDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { getNextExpirations } from '../utils';
import { defaultIndexTickers, defaultMegacapTickers } from './preset_selector';
import { Range } from 'react-range';
import { debounce } from 'underscore';

const FilterSelect = ({ name, value, onChange, options, className }) => (
  <select name={name} value={value} onChange={onChange} className={className}>
    {options.map(option => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </select>
);

const ExcludeTickerCheckbox = ({ id, label, tickerGroup, filters, handleFilterChange }) => {
  const isChecked =
    !!filters.exclude_ticker && tickerGroup.every(ticker => filters.exclude_ticker.includes(ticker));

  const onToggle = (event) => {
    if (event.target.checked) {
      handleFilterChange({
        exclude_ticker: filters.exclude_ticker
          ? filters.exclude_ticker.concat(tickerGroup)
          : tickerGroup
      });
    } else {
      handleFilterChange({
        exclude_ticker: filters.exclude_ticker
          ? filters.exclude_ticker.filter(ticker => !tickerGroup.includes(ticker))
          : undefined
      });
    }
  };

  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        id={id}
        name={id}
        onChange={onToggle}
        checked={isChecked}
        className="cursor-pointer h-4 w-4 rounded border-zinc-500 bg-zinc-800 text-dgreen-600 focus:ring-dgreen-600"
      />
      <label
        htmlFor={id}
        className={`cursor-pointer ml-2 text-sm ${isChecked ? 'text-dgreen-600' : 'text-zinc-400'
          }`}
      >
        {label}
      </label>
    </div>
  );
};

const PriceRangeSlider = ({ values: initValues, onChange, maxValue = 5 }) => {
  const [values, setValues] = useState(initValues);
  const formatValue = (value) => {
    if (value >= maxValue) return '∞';
    return value.toFixed(2);
  };

  // Ensure values are valid numbers and within range
  const safeValues = values.map(v => {
    const num = parseFloat(v);
    if (isNaN(num)) return 0;
    return Math.min(Math.max(num, 0), maxValue);
  }).sort((a, b) => a - b); // Ensure values are sorted

  // Create a stable debounced function that persists across renders
  const debouncedOnChange = useCallback(
    debounce((newValues) => {
      onChange(newValues);
    }, 500),
    [onChange]
  );

  // Clean up the debounced function on unmount
  useEffect(() => {
    return () => {
      debouncedOnChange.cancel();
    };
  }, [debouncedOnChange]);

  return (
    <div className="w-full py-4 px-2">
      <Range
        step={0.1}
        min={0}
        max={maxValue}
        values={safeValues}
        onChange={(values) => {
          setValues(values)
          debouncedOnChange(values)
        }}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            className="w-full h-1 bg-zinc-700 rounded-full"
            style={{
              ...props.style,
            }}
          >
            <div
              className="h-full bg-dgreen-600 rounded-full"
              style={{
                position: 'absolute',
                left: `${(safeValues[0] / maxValue) * 100}%`,
                right: `${100 - (safeValues[1] / maxValue) * 100}%`,
              }}
            />
            {children}
          </div>
        )}
        renderThumb={({ props, index }) => (
          <div
            {...props}
            className="h-4 w-4 bg-dgreen-600 rounded-full focus:outline-none focus:ring-2 focus:ring-dgreen-600 focus:ring-offset-2"
            style={{
              ...props.style,
              cursor: 'pointer',
            }}
          >
            <div className="absolute top-6 left-1/2 transform -translate-x-1/2 text-xs text-zinc-300">
              {formatValue(safeValues[index])}
            </div>
          </div>
        )}
      />
    </div>
  );
};

const TradeFilters = ({ filters, onFilterChange: handleFilterChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onFilterChange = (event) => {
    handleFilterChange({ [event.target.name]: event.target.value });
  };

  // Basic styles for our select dropdowns
  const baseSelectClass = "order-select bg-zinc-800 border-zinc-700";

  // Build expiration dropdown options using date-fns and date-fns-tz
  const expirationOptions = getNextExpirations().map(date => {
    const timeZone = 'America/New_York';
    const expirationDate = utcToZonedTime(new Date(date), timeZone);
    const daysToExpiration = differenceInDays(
      expirationDate,
      utcToZonedTime(new Date(), timeZone)
    );
    return {
      value: date,
      label: `${format(expirationDate, 'MMMdd')} (${daysToExpiration}d)`
    };
  });

  return (
    <div className="flex flex-col">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-x-2 px-3 py-3 text-sm font-medium text-zinc-300 hover:text-white transition-colors"
      >
        <AdjustmentsHorizontalIcon className="w-5 h-5" />
        <span>Custom Filters</span>
        <svg
          className={`w-4 h-4 transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      <div
        className={`transition-all duration-300 ease-in-out overflow-hidden ${isOpen ? 'md:mb-2 max-h-96 opacity-100' : 'max-h-0 opacity-0'
          }`}
      >
        <div className="bg-zinc-900/50 backdrop-blur-sm md:rounded-lg py-4 border border-zinc-800">
          <div className="px-2 no-scrollbar overflow-x-auto flex flex-row md:flex-nowrap gap-x-2">
            <FilterSelect
              name="calls_or_puts"
              value={filters.calls_or_puts}
              onChange={onFilterChange}
              options={[
                { value: "all", label: "Calls & Puts" },
                { value: "call", label: "Calls" },
                { value: "put", label: "Puts" }
              ]}
              className={`${baseSelectClass} w-32`}
            />

            <FilterSelect
              name="side"
              value={filters.side}
              onChange={onFilterChange}
              options={[
                { value: "all", label: "Buy & Sell" },
                { value: "buy", label: "Buy" },
                { value: "sell", label: "Sell" }
              ]}
              className={`${baseSelectClass} w-28`}
            />

            <FilterSelect
              name="expiration_date"
              value={filters.expiration_date}
              onChange={onFilterChange}
              options={[
                { value: "all", label: "All Expirations" },
                ...expirationOptions
              ]}
              className={`${baseSelectClass} w-36`}
            />

            <FilterSelect
              name="trade_type"
              value={filters.trade_type}
              onChange={onFilterChange}
              options={[
                { value: "all", label: "All Types" },
                { value: "regular", label: "Regular" },
                { value: "multi_leg", label: "Multi Leg" },
                { value: "floor", label: "Floor" }
              ]}
              className={`${baseSelectClass} w-28`}
            />
          </div>

          <div className="px-2 md:px-4 mt-2 flex flex-row items-center gap-x-4">
            {!filters.ticker && (
              <>
                <ExcludeTickerCheckbox
                  id="exclude_indexes"
                  label="Exclude Indexes"
                  tickerGroup={defaultIndexTickers}
                  filters={filters}
                  handleFilterChange={handleFilterChange}
                />
                <ExcludeTickerCheckbox
                  id="exclude_megacaps"
                  label="Exclude Megacaps"
                  tickerGroup={defaultMegacapTickers}
                  filters={filters}
                  handleFilterChange={handleFilterChange}
                />
              </>
            )}
            <div className="flex items-center">
              <input
                type="checkbox"
                id="unusual_only"
                name="unusual_only"
                onChange={(event) =>
                  handleFilterChange({ unusual_only: event.target.checked.toString() })
                }
                checked={filters.unusual_only === 'true'}
                className="cursor-pointer h-4 w-4 rounded border-zinc-500 bg-zinc-800 text-dgreen-600 focus:ring-dgreen-600"
              />
              <label
                htmlFor="unusual_only"
                className={`cursor-pointer ml-2 text-sm ${filters.unusual_only === 'true' ? 'text-dgreen-600' : 'text-zinc-400'
                  }`}
              >
                Unusual Only
              </label>
            </div>
          </div>
          <div className="border-t border-zinc-800 mt-4 pt-2 px-2 md:px-4 my-2 flex flex-col md:flex-row items-start">
            <div className='w-full'>
              <span className="text-sm text-zinc-400">Price Range</span>
              <div className="w-full md:max-w-[200px]">
                <PriceRangeSlider
                  maxValue={8}
                  values={[
                    Math.min(parseFloat(filters.min_price || 0) || 0, 8),
                    Math.min(parseFloat(filters.max_price || 8) || 8, 8)
                  ]}
                  onChange={(values) => {
                    const updates = {
                      min_price: values[0].toString(),
                      max_price: values[1] < 8 ? values[1].toString() : undefined
                    };
                    handleFilterChange(updates);
                  }}
                />
              </div>
            </div>
            <div className='w-full mt-6 md:mt-0'>
              <span className="text-sm text-zinc-400">Premium Range</span>
              <div className="w-full md:max-w-[200px]">
                <PriceRangeSlider
                  maxValue={1000000}
                  values={[
                    Math.min(parseFloat(filters.min_premium || 0) || 0, 1000000),
                    Math.min(parseFloat(filters.max_premium || 1000000) || 1000000, 1000000)
                  ]}
                  onChange={(values) => {
                    const updates = {
                      min_premium: values[0].toString(),
                      max_premium: values[1] < 1000000 ? values[1].toString() : undefined
                    };
                    handleFilterChange(updates);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeFilters; 