import React, { useEffect, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { useStockList } from '../hooks/use_stocklists';
import StockRow from './stock_row';
import { StockWidgetPending } from './stock_widget';

const StockListWidget = ({ listType = 'trending' }) => {
  const { data, isPending, refetch } = useStockList({ listType });
  const stocks = (data?.[listType] || []).slice(0, 12);
  const listTypeToHeader = {
    trending: 'Trending',
    cheapest: 'Cheap Options',
  };

  const setupRetryOnEmpty = useCallback(() => {
    if (!isPending && stocks.length === 0) {
      const timer = setTimeout(refetch, listType === 'trending' ? 4000 : 10000);
      return () => clearTimeout(timer);
    }
  }, [isPending, stocks.length, refetch, listType]);
  useEffect(setupRetryOnEmpty, [setupRetryOnEmpty]);

  const HeaderSection = () => (
    <div className="flex justify-between items-center mb-2">
      <div className="flex flex-row items-center gap-x-1">
        <h2 className="section-header">{listTypeToHeader[listType]}</h2>
      </div>
    </div>
  );

  const LoadingState = () => (
    <div className="pl-4 space-x-2 grid grid-cols-3">
      {[...Array(3)].map((_, i) => (
        <StockWidgetPending key={i} />
      ))}
    </div>
  );

  const StockSlider = () => {
    const stockGroups = [];
    for (let i = 0; i < stocks.length; i += 4) {
      stockGroups.push(stocks.slice(i, i + 4));
    }

    return (
      <Swiper
        grabCursor={true}
        pagination={{ clickable: false }}
        spaceBetween={8}
        slidesPerView="auto"
        slidesOffsetBefore={16}
        slidesOffsetAfter={16}
        autoplay={false}
        loop={false}
        modules={[Pagination, Autoplay]}
        className="w-full h-fit">
        {stockGroups.map((group, index) => (
          <SwiperSlide key={index} className="!w-[280px]">
            <div className="bg-zinc-900 border border-zinc-800 rounded-lg px-2 py-1 divide-y divide-zinc-800 w-full flex flex-col">
              {group.map(stock => (
                <StockRow key={stock.symbol} stock={stock} className="!py-2" />
              ))}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    );
  };

  return (
    <div className="mt-4 flex flex-col">
      <div className="mx-4">
        <HeaderSection />
      </div>
      {isPending || stocks.length === 0 ? <LoadingState /> : <StockSlider />}
    </div>
  );
};

export { StockListWidget };