import React, { useRef, useEffect } from 'react';
import { AdjustmentsHorizontalIcon, CurrencyDollarIcon, BellAlertIcon, FireIcon, ScaleIcon, BriefcaseIcon } from '@heroicons/react/24/solid';
import { getNextExpirations } from '../utils';

const defaultIndexTickers = ['SPY', 'QQQ', 'IWM'];
const defaultMegacapTickers = ['NVDA', 'TSLA', 'AAPL', 'MSFT', 'META', 'GOOG', 'GOOGL', 'AMZN'];

export { defaultIndexTickers, defaultMegacapTickers };

const defaultPresets = [
  {
    name: 'Largest Trades',
    icon: CurrencyDollarIcon,
    filters: {
      sort_field: 'notional',
      sort_direction: 'desc',
      unusual_only: 'false',
      trade_type: 'regular',
      calls_or_puts: 'all',
      expiration_date: 'all',
      side: 'all',
      exclude_ticker: defaultIndexTickers,
    }
  },
  {
    name: 'Unusual Trades',
    icon: BellAlertIcon,
    filters: {
      unusual_only: 'true',
      sort_field: 'notional',
      sort_direction: 'desc',
      trade_type: 'regular',
      calls_or_puts: 'all',
      expiration_date: 'all',
      side: 'buy',
      exclude_ticker: defaultIndexTickers.concat(defaultMegacapTickers)
    }
  },
  {
    name: 'Unusual Weeklies',
    icon: FireIcon,
    filters: {
      unusual_only: 'true',
      sort_field: 'notional',
      sort_direction: 'desc',
      trade_type: 'regular',
      calls_or_puts: 'all',
      expiration_date: getNextExpirations()[0],
      side: 'buy',
      exclude_ticker: defaultIndexTickers.concat(defaultMegacapTickers)
    }
  },
  {
    name: 'Put Sellers',
    icon: ScaleIcon,
    filters: {
      unusual_only: 'false',
      sort_field: 'notional',
      sort_direction: 'desc',
      trade_type: 'regular',
      calls_or_puts: 'put',
      expiration_date: 'all',
      side: 'sell',
      exclude_ticker: defaultIndexTickers.concat(defaultMegacapTickers)
    }
  },
  {
    name: 'Market Makers',
    icon: BriefcaseIcon,
    filters: {
      unusual_only: 'false',
      sort_field: 'notional',
      sort_direction: 'desc',
      trade_type: 'floor',
      calls_or_puts: 'all',
      expiration_date: 'all',
      side: 'buy',
      exclude_ticker: defaultIndexTickers.concat(defaultMegacapTickers)
    }
  }
];

export { defaultPresets };

const PresetSelector = ({ presets = defaultPresets, onPresetSelect, filters }) => {
  const selectedClass = 'bg-dgreen-400 text-dgreen-900';
  const unselectedClass = 'bg-zinc-800 text-zinc-300 hover:text-zinc-300';
  const buttonRefs = useRef({});

  const getMatchingPreset = () => {
    const matchingPreset = presets.find(preset =>
      Object.entries(preset.filters).every(([key, value]) =>
        filters[key] === value
      )
    );
    return matchingPreset?.name || null;  // Return null instead of 'Custom'
  };

  const effectivePreset = getMatchingPreset();

  // Add useEffect to handle scrolling
  useEffect(() => {
    if (effectivePreset && buttonRefs.current[effectivePreset]) {
      buttonRefs.current[effectivePreset].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center'
      });
    }
  }, [effectivePreset]);

  return (
    <div className="overflow-x-auto no-scrollbar">
      <div className="flex space-x-2">
        {presets.map(({ name, icon: Icon, filters: presetFilters }) => {
          const buttonClass = effectivePreset === name ? selectedClass : unselectedClass;
          return (
            <button
              key={name}
              ref={el => buttonRefs.current[name] = el}
              onClick={() => onPresetSelect(name, presetFilters)}
              className={`flex items-center space-x-2 px-4 py-2 rounded-full whitespace-nowrap ${buttonClass}`}>
              <Icon className="w-5 h-5" />
              <span className='font-semibold text-sm'>{name}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default PresetSelector;