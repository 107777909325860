import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { QueryClientProvider } from '@tanstack/react-query';
import { market_trades_api_path } from '../routes';
import TradesTable from './trades_table';
import PresetSelector, { defaultPresets } from './preset_selector';
import TradeFilters from './trade_filters';

const TradesContent = ({ ticker, isLoading, trades, onFilterChange, filters }) => {
  if (isLoading) {
    return <div className="p-4">
      <div className="flex flex-row items-center gap-x-2">
        <div className="w-4 h-4 spinner"></div>
        <span>Loading</span>
      </div>
    </div>;
  }

  if (trades.length === 0) {
    return (
      <div className="whitespace-normal p-2 px-4 flex flex-row gap-x-2 text-base text-zinc-400">
        <span>No trades found. Try adjusting the filters or check back later.</span>
      </div>
    );
  }

  return (
    <TradesTable trades={trades} onFilterChange={onFilterChange} sortField={filters.sort_field} sortDirection={filters.sort_direction} />
  );
};

const TradesComponent = ({ ticker = null, showHeader = true, className = 'py-4 flex flex-col md:ml-6' }) => {
  const [filters, setFilters] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    const tickerParam = params.get('ticker') || ticker;
    const presetParam = params.get('preset') || 'Largest Trades';
    const defaultPreset = defaultPresets.find(p => p.name === presetParam);

    // Create an object from URL parameters, filtering out null/undefined values
    const urlParams = Object.fromEntries(
      Array.from(params.entries())
        .filter(([_, value]) => value != null)
        .map(([key, value]) => {
          // Parse exclude_ticker into array if it's a string
          if (key === 'exclude_ticker' && typeof value === 'string') {
            return [key, value.split(',')];
          }
          return [key, value];
        })
    );

    return {
      ...defaultPreset.filters,  // Start with preset defaults
      ...urlParams,             // Override with any URL parameters
      ...(tickerParam ? { ticker: tickerParam } : {}),
      preset: presetParam,
    };
  });

  const { data: response = { trades: [] }, isLoading } = useQuery({
    queryKey: ['trades', filters],
    queryFn: () => {
      return fetch(market_trades_api_path(filters)).then(res => res.json());
    },
    refetchInterval: 30000
  });

  const handleFilterChange = (filterChange) => {
    const newFilters = {
      ...filters,
      ...filterChange
    };
    setFilters(newFilters);

    const params = new URLSearchParams(window.location.search);
    Object.entries(newFilters).forEach(([key, value]) => {
      if (value != null) {
        params.set(key, value);
      } else {
        params.delete(key);
      }
    });
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.pushState({}, '', newUrl);
  };

  const handlePresetSelect = (presetName, presetFilters) => {
    handleFilterChange({
      ...presetFilters,
      preset: presetName
    });
  };

  const trades = response.trades;
  trades.forEach(trade => {
    trade.chgPct = ((trade.last_quote - trade.price) / trade.price) * 100;
    const chg = trade.last_quote ? (trade.last_quote - trade.price).toFixed(2) : null;
    var chgPct = chg ? ((chg / trade.price) * 100).toFixed(1) : null;
    trade.chgPct = trade.side === 'sell' ? -chgPct : chgPct;
  });
  return (
    <div className={`${className}`}>
      {showHeader && (
        <div>
          <PresetSelector
            onPresetSelect={handlePresetSelect}
            filters={filters} />
        </div>
      )}

      <div className="flex flex-col md:max-w-2xl">
        <TradeFilters
          filters={filters}
          onFilterChange={handleFilterChange} />
        <TradesContent
          filters={filters}
          ticker={ticker}
          onFilterChange={handleFilterChange}
          isLoading={isLoading}
          trades={trades} />
      </div>
    </div>
  );
};

export default function WrappedTradesComponent(props) {
  return (
    <QueryClientProvider client={window.Blaze.getQueryClient()}>
      <TradesComponent ticker={props.ticker} showHeader={props.showHeader} className={props.className} />
    </QueryClientProvider>
  );
}
