import { useQuery } from '@tanstack/react-query';
import { watchlist_api_path, watchlist_list_api_path } from '../routes';

const WATCHLIST_LIST_QUERY_KEY = 'watchlist_list';
const WATCHLIST_API_QUERY_KEY = 'watchlist_api';

const useWatchlist = (refetchInterval = false) => {
  return useQuery({
    queryKey: [WATCHLIST_LIST_QUERY_KEY],
    queryFn: () =>
      fetch(watchlist_list_api_path()).then((response) => response.json()),
    refetchInterval: refetchInterval
  });
};

const useWatchlistApi = (refetchInterval = false) => {
  return useQuery({
    queryKey: [WATCHLIST_API_QUERY_KEY],
    queryFn: () => fetch(watchlist_api_path()).then(res => res.json()),
    refetchInterval: refetchInterval
  });
};

const invalidateWatchlist = (queryClient) => {
  queryClient.invalidateQueries({ queryKey: [WATCHLIST_LIST_QUERY_KEY] });
  queryClient.invalidateQueries({ queryKey: [WATCHLIST_API_QUERY_KEY] });
};

export { useWatchlist, useWatchlistApi, invalidateWatchlist };