import React, { useState, useRef, useEffect, useMemo } from 'react';
import { MagnifyingGlassIcon, PlusIcon, ArrowUpIcon, ChevronUpDownIcon, ArrowDownIcon } from '@heroicons/react/24/solid';
import WatchlistItem from './watchlist_item';
import { Button } from "./catalyst/button";
import { VariableSizeList } from 'react-window';
import { Stock } from '../types/stock';
import { Switch } from './catalyst/switch';
import Tracker from './tracker';
import { useSearchDialog } from '../contexts/search_dialog_context';
import { useWatchlistApi } from '../hooks/use_watchlist';

const WatchList = ({ className = '' }) => {
  const [sortField, setSortField] = useState<'name' | 'change' | 'price' | 'iv30'>('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [showIV, setShowIV] = useState(false);
  const { openSearchDialog } = useSearchDialog();
  const listRef = useRef<VariableSizeList>(null);
  const scrollOffsetRef = useRef<number>(0);

  const { data, refetch, isLoading, dataUpdatedAt } = useWatchlistApi(false);

  useEffect(() => {
    if (dataUpdatedAt && scrollOffsetRef.current > 0 && listRef.current) {
      listRef.current.scrollTo(scrollOffsetRef.current);
    }
  }, [dataUpdatedAt]);

  const stocks: Stock[] = data ? data.watchlist : [];
  const sortedStocks = useMemo(() => {
    return [...stocks].sort((a, b) => {
      if (a.pinned !== b.pinned) {
        return a.pinned ? -1 : 1;
      }

      switch (sortField) {
        case 'name':
          return sortDirection === 'asc'
            ? a.symbol.localeCompare(b.symbol)
            : b.symbol.localeCompare(a.symbol);
        case 'change':
          return sortDirection === 'asc'
            ? a.changePercent - b.changePercent
            : b.changePercent - a.changePercent;
        case 'price':
          return sortDirection === 'asc'
            ? a.latestPrice - b.latestPrice
            : b.latestPrice - a.latestPrice;
        case 'iv30':
          return sortDirection === 'asc'
            ? a.iv_edge - b.iv_edge
            : b.iv_edge - a.iv_edge;
        default:
          return 0;
      }
    });
  }, [stocks, sortField, sortDirection]);

  const stockStats = useMemo(() => {
    if (!stocks.length) return { upPercent: 0, downPercent: 0 };
    const upStocks = stocks.filter(stock => stock.changePercent > 0).length;
    const upPercent = (upStocks / stocks.length) * 100;
    return {
      upPercent,
      downPercent: 100 - upPercent
    };
  }, [stocks]);

  const LoadingState = () => (
    <div className="flex justify-center items-center py-2 gap-x-2 text-zinc-500">
      <div className="w-5 h-5 spinner"></div>
      <div className="text-zinc-400 text-sm">Loading Watchlist</div>
    </div>
  );

  const EmptyState = () => (
    <div className="flex flex-col items-center py-4 bg-zinc-900 text-zinc-300">
      <p className="mb-2 text-zinc-400 text-lg">Your watchlist is empty</p>
      <Button
        onClick={() => {
          Tracker.track('watchlist.add_first_symbol')
          openSearchDialog()
        }}>
        <PlusIcon className="w-4 h-4 mr-2" />
        <span className="text-sm text-zinc-300">Add your first symbol</span>
      </Button>
    </div>
  );

  const handleSort = (field: 'name' | 'change' | 'price' | 'iv30') => {
    if (sortField === field) {
      setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection(field === 'name' ? 'asc' : 'desc');
    }
    listRef.current?.scrollTo(0);
    Tracker.track('watchlist.sort', { field: field, direction: sortDirection });
  };

  const WatchlistTableHeader = () => {
    const columnClasses = "text-zinc-400 text-xs font-medium flex items-center cursor-pointer";
    const renderSortIcon = (field) => {
      if (sortField === field) {
        return <ArrowUpIcon className={`w-3 h-3 ml-0.5 ${sortDirection === 'desc' && 'rotate-180'}`} />;
      }
      return <ChevronUpDownIcon className="w-4 h-4 text-zinc-400" aria-hidden="true" />;
    };

    return (
      <div className="pr-4 pl-2 w-full flex flex-row justify-between bg-zinc-900 border-b border-zinc-800">
        <div className="flex flex-row items-center">
          <div className={`py-2 w-[155px] ${columnClasses}`} onClick={() => handleSort('name')}>
            Name
            {renderSortIcon('name')}
          </div>
        </div>
        <div className="flex flex-row items-center">
          {showIV && (
            <div className={`text-right justify-end w-[50px] py-2 pr-2 ${columnClasses}`} onClick={() => handleSort('iv30')}>
              IV
              {renderSortIcon('iv30')}
            </div>
          )}
          <div className={`text-right justify-end w-[60px] py-2 ${columnClasses}`} onClick={() => handleSort('price')}>
            Price
            {renderSortIcon('price')}
          </div>
          <div className={`hidden text-right justify-end w-[60px] py-2 ${columnClasses}`} onClick={() => handleSort('change')}>
            CHG
            {renderSortIcon('change')}
          </div>
          <div className={`text-right justify-end w-[80px] py-2 ${columnClasses}`} onClick={() => handleSort('change')}>
            CHG %
            {renderSortIcon('change')}
          </div>
        </div>
      </div>
    );
  };

  const estimatedRowHeight = 70;

  const MarketSentimentBar = () => {
    const upCount = stocks.filter(stock => stock.changePercent > 0).length;
    const downCount = stocks.length - upCount;

    return stockStats.upPercent > 0 && (
      <div className="w-full flex flex-col gap-y-1">
        <div className="mt-1 px-2 w-full hidden justify-between text-xs">
          {upCount > 0 && <span className="text-green-500">{upCount} UP</span>}
          {downCount > 0 && <span className="text-red-500">{downCount} DOWN</span>}
        </div>
        <div className="w-full h-0.5 flex">
          <div className="h-full bg-green-500"
            style={{ width: `${stockStats.upPercent}%` }} />
          <div className="h-full bg-red-500"
            style={{ width: `${stockStats.downPercent}%` }} />
        </div>
      </div>
    );
  };

  const WatchlistList = () => {
    const pinnedStocks = sortedStocks.filter(stock => stock.pinned);
    const dividerHeight = 24;
    const totalItems = sortedStocks.length + (pinnedStocks.length > 0 ? 2 : 0);
    const totalHeight = Math.min(totalItems * estimatedRowHeight, estimatedRowHeight * 10);

    const getItemSize = (index: number) => {
      if ((index === 0 && pinnedStocks.length > 0) ||
        (index === pinnedStocks.length + 1 && pinnedStocks.length > 0)) {
        return dividerHeight;
      }
      return estimatedRowHeight;
    };

    const getItemForIndex = (index: number) => {
      // First header
      if (index === 0 && pinnedStocks.length > 0) {
        return (
          <div className="px-2 py-1 bg-zinc-800/50 text-zinc-500 text-xs font-medium">
            Pinned Stocks
          </div>
        );
      }

      // Second header
      if (index === pinnedStocks.length + 1 && pinnedStocks.length > 0) {
        return (
          <div className="px-2 py-1 bg-zinc-800/50 text-zinc-500 text-xs font-medium">
            Watchlist
          </div>
        );
      }

      // Get the actual stock index by subtracting headers
      let stockIndex;
      if (pinnedStocks.length > 0) {
        stockIndex = index <= pinnedStocks.length ? index - 1 : index - 2;
      } else {
        stockIndex = index;
      }

      const stock = sortedStocks[stockIndex];
      if (!stock) return null;

      return (
        <WatchlistItem
          key={stock.symbol}
          stock={stock}
          rowHeight={estimatedRowHeight}
          refetch={refetch}
          showIV={showIV}
        />
      );
    };

    return (
      <div className="flex flex-col relative">
        <MarketSentimentBar />
        <WatchlistTableHeader />
        <VariableSizeList
          ref={listRef}
          height={totalHeight}
          overscanCount={3}
          width="100%"
          itemCount={totalItems}
          itemSize={getItemSize}
          initialScrollOffset={scrollOffsetRef.current}
          onScroll={({ scrollOffset }) => {
            scrollOffsetRef.current = scrollOffset;
          }}
        >
          {({ index, style }) => (
            <div style={style}>
              {getItemForIndex(index)}
            </div>
          )}
        </VariableSizeList>
      </div>
    );
  };

  const WatchlistBar = () => (
    <div className='flex flex-col pb-2 border-b border-zinc-800'>
      <div className="pl-2 flex flex-row items-center justify-between">
        <div className='section-header'>My Watchlist</div>
        <div className='flex flex-row items-center justify-end mr-0 divide-x divide-zinc-800'>
          <div className='px-3 cursor-pointer flex flex-row items-center gap-x-2'>
            <Switch checked={showIV} onChange={() => {
              setShowIV(!showIV)
              Tracker.track('watchlist.iv_toggle', { showIV: showIV })
            }} />
            <span className={`text-sm text-zinc-300`}>IV</span>
          </div>
          <div className='px-3 cursor-pointer' onClick={() => {
            openSearchDialog()
            Tracker.track('watchlist.open_symbol_search')
          }}>
            <MagnifyingGlassIcon className='w-6 h-6' />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={`mt-4 flex flex-col h-full ${className}`}>
      <WatchlistBar />
      <div className="flex flex-col">
        {isLoading ? <LoadingState /> :
          sortedStocks.length === 0 ? <EmptyState /> :
            <WatchlistList />}
      </div>
    </div>
  );
};

export default WatchList;
