import React from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { useSearchDialog } from '../contexts/search_dialog_context';

const SearchInput = ({ iconOnly = false }) => {
  const { openSearchDialog } = useSearchDialog();

  if (iconOnly) {
    return (
      <MagnifyingGlassIcon
        className="w-6 h-6 text-white hover:text-zinc-300 cursor-pointer"
        onClick={openSearchDialog}
      />
    );
  }

  return (
    <div onClick={openSearchDialog} className="px-1 py-1 cursor-pointer bg-zinc-800 hover:bg-zinc-800 rounded-lg flex flex-row items-center">
      <div className="ml-2 w-5 h-5 text-zinc-500"><MagnifyingGlassIcon /></div>
      <div className="ml-2 border-0 py-1 text-zinc-500">Search by name or ticker</div>
    </div>
  );
}

export default SearchInput;