import React, { useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { navigate } from '../navigator';
import { show_symbol_path, watchlist_add_api_path, watchlist_remove_api_path } from '../routes';
import Tracker from './tracker';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { PlusIcon } from '@heroicons/react/24/outline';
import { invalidateWatchlist, useWatchlist } from '../hooks/use_watchlist';
import { useQuote } from '../hooks/use_quote';
import { getColorClasses } from '../utils/colors';

const StockRow = ({ stock, onClose, className = '' }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isActionHidden, setIsActionHidden] = useState(true);
  const queryClient = useQueryClient();
  const { data: watchlistResponse, isPending: watchlistPending, refetch: refetchWatchlist } = useWatchlist();
  const { data: marketQuoteResponse, isPending: marketQuotePending } = useQuote(stock.symbol);

  const [isAdded, setIsAdded] = useState(false);
  useEffect(() => {
    if (!watchlistPending) {
      setIsLoading(false);
      setIsActionHidden(false);
      const stockAdded = watchlistResponse?.watchlist.map(w => w.symbol).includes(stock.symbol);
      setIsAdded(stockAdded);
    }
  }, [watchlistPending, stock, watchlistResponse]);

  const handleNavigate = () => {
    navigate(show_symbol_path(stock.symbol));
    if (onClose) {
      onClose();
    }
  };

  const watchlistAdd = (symbol) => {
    setIsLoading(true);
    Tracker.track('watchlist.add_symbol', { symbol: symbol });
    fetch(watchlist_add_api_path({ symbols: [symbol] }), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Blaze.csrfToken(),
      },
      body: JSON.stringify({ symbol }),
    }).then(response => response.json())
      .then(() => {
        setIsLoading(false);
        setIsAdded(true);
        invalidateWatchlist(queryClient);
      });
  };

  const watchlistRemove = (symbol) => {
    setIsLoading(true);
    Tracker.track('watchlist.remove_symbol', { symbol: symbol });
    fetch(watchlist_remove_api_path({ symbols: [symbol] }), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Blaze.csrfToken(),
      },
      body: JSON.stringify({ symbol }),
    }).then(response => response.json())
      .then(() => {
        setIsLoading(false);
        setIsAdded(false);
        invalidateWatchlist(queryClient);
      });
  };

  const AddRemoveButton = ({stock}) => {
    return <div className="w-[32px] flex items-end justify-end">
      {isActionHidden ? (<>
        <div className="w-8 h-8 rounded-full animate-pulse flex items-center justify-center bg-zinc-800"></div>
      </>) : isLoading ? (
        <div className="w-8 h-8 flex items-center justify-center">
          <div className="spinner h-6 w-6" />
        </div>
      ) : isAdded ? (
        <CheckCircleIcon className="w-7 h-7 text-zinc-500 rounded-full"
          onClick={(e) => {
            e.stopPropagation();
            watchlistRemove(stock.symbol)
          }} />) : (
        <div className="-mr-[6px] w-8 h-8 flex items-center ">
          <PlusIcon
            className="border-2 border-dgreen-500 w-6 h-6 text-dgreen-500 rounded-full hover:text-dgreen-300 transition-colors"
            onClick={(e) => {
              e.stopPropagation();
              watchlistAdd(stock.symbol);
            }}
          />
        </div>
      )}
    </div>
  }

  const QuoteDisplay = ({ quote }) => {
    if (!quote) return null;

    const colorClasses = getColorClasses(quote.changePercent);
    const isPositive = parseFloat(quote.changePercent) >= 0;
    return (
      <div className="text-right flex flex-col mr-2">
        <div className={`flex flex-row ${colorClasses.text}`}>
          <div className='mr-[2px] hidden'>{isPositive ? '▲' : '▼'}</div>
          <div className="font-semibold w-[68px]">{isPositive ? '+' : ''}{quote.changePercent}%</div>
        </div>
        <div className="text-zinc-400 text-sm">{quote.latestPrice}</div>
      </div>
    );
  };

  return (
    <div className={`cursor-pointer flex items-center justify-between py-3 px-0 ${className}`} onClick={handleNavigate}>
      <div className="flex flex-row items-center gap-3">
        <div className="w-8 h-8">
          <img
            src={`/data-img/symbol/${stock.symbol}`}
            alt={stock.name}
            className="rounded-full w-8 h-8"
          />
        </div>
        <div>
          <div className="text-sky-300 font-bold">{stock.symbol}</div>
          <div className="text-zinc-400 text-sm truncate max-w-28">{stock.name}</div>
        </div>
      </div>
      <div className="flex flex-row items-center justify-end">
        {!marketQuotePending && <QuoteDisplay
          quote={marketQuoteResponse}
        />}
        <AddRemoveButton stock={stock} />
      </div>
    </div>
  );
};

export default StockRow;